window.addEventListener('load', () => {
  const length = document.querySelector('.hero__background').getAttribute('data-length');

  if(length > 1) {
      const style = document.createElement('style');

      style.textContent = `
          @keyframes fade {
              0% {
                  opacity: 0;
              }
              4% {
                  opacity: 1;
              }
              ${100 / length}% {
                  opacity: 1;
              }
              ${100 / length + 4}% {
                  opacity: 0;
              }
              100% {
                  opacity: 0;
              }
          }
      `;

      document.head.appendChild(style);
  }
});
